// Colors
export const GRAY = "rgba(0,0,0,0.5)";
export const GRAY_LIGHT = "rgba(0,0,0,0.15)";
export const GREEN = "#78B833";
export const RED = "#E61917";
export const YELLOW_DARK = "#FEDD2E";
export const YELLOW_LIGHT = "#FFF004";
export const CONTENT_COLOR = "#f5f4f0";

// Transitions
export const PAGE_TRANSITION_TIMEOUT = 350;
export const TRANSITION_TIME_BASE = 180;
export const TRANSITION_TIME_SLOW = TRANSITION_TIME_BASE * 1.25;
export const TRANSITION_TIME_FAST = TRANSITION_TIME_BASE * 0.25;
