import { css } from "styled-components";

import {
  TRANSITION_TIME_SLOW,
  TRANSITION_TIME_BASE,
  TRANSITION_TIME_FAST,
} from "./constants";

export const easeIn = css`
  transition: ${TRANSITION_TIME_SLOW}ms ease-in;
`;

export const easeOut = css`
  transition: ${TRANSITION_TIME_BASE}ms ease-out;
`;

export const easeOutSlow = css`
  transition: ${TRANSITION_TIME_BASE * 1.25}ms ease-out;
`;

export const easeOutFast = css`
  transition: ${TRANSITION_TIME_FAST}ms ease-out;
`;

export const opacityHover = css`
  ${easeIn};

  &:hover {
    ${easeOut};
    opacity: 0.75;
  }
`;
